<template>
  <b-card-code>
    <b-row>
      <b-col md="4">
        <b-form-group>
          <b-card-text>
            <span>{{ $t('Select a Child') }} </span>
          </b-card-text>
          <select v-model="student_index" class="form-control"
                  name="classes_id" >
            <option :value="null">Select one</option>
            <option v-for="(student, key) in students" :key="key" :value="key">{{student.cadet_no}} - {{student.userable?student.userable.name:''}}</option>
          </select>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="selected_payments"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <span v-else-if="props.column.field === 'created_at'">
          {{ moment(props.row.created_at).format('Y-MM-D') }}
        </span>

      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BPagination, BFormGroup, BFormInput, BFormSelect,
  BRow,BCol,BCardText,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import apiCall from "@/libs/axios";
import moment from "moment"
export default {
  name:'PaymentHistory',
  components: {
    BCardCode,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,BRow,BCol,BCardText,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Invoice No',
          field: 'invoice_no',
        },
        {
          label: 'Class',
          field: 'classes.name',
        },
        {
          label: 'Academic Year',
          field: 'academic_year.year',
        },
        {
          label: 'Fee Type',
          field: 'student_fee_type.name',
        },
        {
          label: 'Total Paid',
          field: 'total_amount',
        },
        {
          label: 'Payment Type',
          field: 'payment_type.name',
        },
        {
          label: 'Payment Info',
          field: 'transaction_information',
        },
        {
          label: 'Payment Date',
          field: 'created_at',
        },
      ],
      searchTerm: '',
      status:{
        0:'Pending',
        1:'Approved',
        2:'Declined'
      },
      payments:[],
      selected_payments:[],
      students:[],
      student_index:null,
    }
  },
  methods: {
    async getMaterials(){
      await apiCall.get('/parent/child/payments').then((response)=>{
        this.students=response.data.students;
        this.payments=response.data.payments;
      }).catch(()=>{
        this.students=[];
        this.payments=[];
      })
    },
    findStd(){
      let ss = this.students[this.student_index];
      if(ss) this.selected_student=ss;
      else this.selected_student= {};
    },
    findPayments(){
      let ss = this.payments[this.student_index];
      if(ss) this.selected_payments=ss;
      else this.selected_payments=[];
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
      this.getMaterials();
  },
  watch: {
    student_index(){
      this.findStd();
      this.findPayments();
    }
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>